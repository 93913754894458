export default [
  {
    key: "companyId",
    label: "field.company",
    rules: "required",
    type: "nAsynSingleSelection",
    repository: "company",
    selectionKey: "id",
    selectionLabel: "name",
    clearable: true,
  },
  {
    key: "search",
    label: "field.name",
    type: "text",
  },
  {
    key: "isEnable",
    label: "field.status",
    rules: "required",
    type: "radio",
    cast: "boolean",
    options: [
      { text: "field.active", value: "1" },
      { text: "field.inactive", value: "0" },
    ],
    lg: "auto",
  },
];
